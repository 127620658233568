import React from 'react';
import { useTranslation } from 'react-i18next';

const Resume = () => {
  const { t, i18n } = useTranslation();
  const data = i18n.language || window.localStorage.i18nextLng;

  return (
    <section className="pt-page" data-id="resume">
      <div className="section-inner custom-page-content">
        <div className="section-title-block second-style">
          <h2 className="section-title">{t('cv.title')}</h2>
          <h5 className="section-description">{t('cv.xp')}</h5>
        </div>

        <div className="section-content">
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <div className="col-inner bs-30">
                <div className="block-title">
                  <h3>{t('edu.title')}</h3>
                </div>

                <div className="timeline timeline-second-style bs-30 clearfix">
                  {['year1', 'year2', 'year3', 'year4'].map((year, index) => (
                    <div className="timeline-item clearfix" key={year}>
                      <div className="left-part">
                        <h5 className="item-period">{t(`edu.${year}`)}</h5>
                        <span className="item-company">{t(`edu.school${index + 1}`)}</span>
                      </div>
                      <div className="divider"></div>
                      <div className="right-part">
                        <h4 className="item-title">{t(`edu.cert${index + 1}`)}</h4>
                        <p>{t(`edu.desc${index + 1}`)}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="block-title">
                  <h3>{t('exp.title')}<span></span></h3>
                </div>

                <div className="timeline timeline-second-style clearfix">
                  {['year1', 'year2', 'year3', 'year4', 'year5', 'year6', 'year7', 'year8'].map((year, index) => (
                    <div className="timeline-item clearfix" key={year}>
                      <div className="left-part">
                        <h5 className="item-period">{t(`exp.${year}`)}</h5>
                        <span className="item-company">{t(`exp.comp${index + 1}`)}</span>
                      </div>
                      <div className="divider"></div>
                      <div className="right-part">
                        <h4 className="item-title">{t(`exp.pos${index + 1}`)}</h4>
                        <p>{t(`exp.desc${index + 1}`)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-4">
              <div className="col-inner">
                <div className="block-title">
                  <h3>{t('cv.title1')}<span></span></h3>
                </div>

                <div className="skills-info skills-second-style">
                  {['sk1', 'sk2', 'sk3', 'sk4'].map((skill, index) => (
                    <div key={skill}>
                      <div className="clearfix">
                        <h4>{t(`cv.${skill}`)}</h4>
                        <div className="skill-value">100%</div>
                      </div>
                      <div className="skill-container">
                        <div className={`skill-percentage skill-${index + 1}`}></div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="block-title ts-10">
                  <h3>{t('cv.title2')}<span></span></h3>
                </div>

                <div className="skills-info skills-second-style">
                  {['sk5', 'sk6', 'sk7', 'sk8', 'sk9', 'sk10', 'sk11', 'sk12', 'sk13', 'sk14', 'sk15', 'sk16', 'sk17', 'sk18', 'sk19', 'sk20', 'sk21'].map((skill, index) => (
                    <div key={skill}>
                      <div className="clearfix">
                        <h4>{t(`cv.${skill}`)}</h4>
                        <div className="skill-value">{index % 2 === 0 ? '95%' : '100%'}</div>
                      </div>
                      <div className="skill-container">
                        <div className={`skill-percentage skill-${index + 5}`}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="col-inner ts-30">
                <a href={data === "fr" ? "./assets/files/CV-v2-french.pdf" : "./assets/files/CV-v2-english.pdf"} target="_blank" className="btn btn-primary">{t('btn.download')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Resume;
