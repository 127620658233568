import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ setDisplay }) => {
  let [isActive, setIsActive] = useState([
    "home-a",
    "about-a",
    "resume-a",
    "portfolio-a",
    "blog-a",
    "contact-a"
  ]);

  useEffect(() => {
    document.getElementById("home-a").classList.add("active");
  }, [])
  const { i18n } = useTranslation();

  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
  }

  const { t } = useTranslation();

  const selectedLi = (val) => {

    isActive.map(id => {
      if (val === id) {
        document.getElementById("site_header").classList.toggle("mobile-menu-hide")
        document.getElementById(id).classList.add("active");
      }
      else {
        document.getElementById(id).classList.remove("active");
      }
    })
  }

  return (
    <header id="site_header" class="header mobile-menu-hide">
      <div class="header-content clearfix">
        <div class="my-photo">
          <img src="./assets/images/photo.jpg" alt="image" />
        </div>
        <div class="site-title-block">
          {/* <div class="site-title">Abdeljalil NACEUR</div> */}
        </div>
        <div class="site-nav">
          <ul id="nav" class="site-main-menu">
            <li id="home-a">
              <a onClick={(e) => selectedLi("home-a")} class="pt-trigger" href="/#/">{t('nav.home')}</a> </li>
            <li id="about-a">
              <a onClick={(e) => selectedLi("about-a")} class="pt-trigger" href="/#/about">{t('nav.about')}</a> </li>
            <li id="resume-a">
              <a onClick={(e) => selectedLi("resume-a")} class="pt-trigger" href="/#/resume">{t('nav.resume')}</a> </li>
            <li id="portfolio-a">
              <a onClick={(e) => selectedLi("portfolio-a")} class="pt-trigger" href="/#/portfolio">{t('nav.portfolio')}</a> </li>
            <li id="blog-a" >
              <a onClick={(e) => selectedLi("blog-a")} class="pt-trigger" href="/#/blog">{t('nav.blog')}</a> </li>
            <li id="contact-a">
              <a onClick={(e) => selectedLi("contact-a")} class="pt-trigger" href="/#/contact">{t('nav.contact')}</a> </li>

          </ul>
        </div>
        <div class="social-links">
          <a href="https://dev4life-tech.medium.com/" target="_blank">
            <i class="fab fa-medium"></i></a>
          <a href="https://www.linkedin.com/in/abdeljalil-naceur-837113184/" target="_blank">
            <i class="fab fa-linkedin"></i></a>

        </div>
        <br></br>
        <div class="social-links">
          <a href="https://github.com/abnaceur" target="_blank"><i
            class="fab fa-github"></i></a>

          <a href="https://stackoverflow.com/users/12872756/abn/" target="_blank"><i
            class="fab fa-stack-overflow"></i></a>

        </div>

        <div class="copyrights">
          {/* <img className="flag-en" src="./assets/images/uk.jpeg" /> */}

          <div class="social-links-a">
          <button className="btn chg-ttl" onClick={e => { handleChange('ar') }}>
              <img src="./assets/images/arabic.png" />
            </button>

        

            <button className="btn chg-ttl" onClick={e => { handleChange('en') }}>
              <img src="./assets/images/uk.jpeg" />
            </button>

            <button className="btn chg-ttl" onClick={e => { handleChange('fr') }}>
              <img src="./assets/images/france.png" />
            </button>

            <button className="btn chg-ttl" onClick={e => { handleChange('cn') }}>
              <img src="./assets/images/china.jpg" />
            </button>

          </div>
          <br></br>
          <br></br>
          © 2020 All rights reserved.</div>
      </div>
    </header>

  )
}

export default Header;