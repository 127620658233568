import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if ((document.location.href).indexOf("email=sent") > -1) {
            localStorage.setItem("notif", true);
            document.location.href = "/#contact";
        }

        let notif = localStorage.getItem("notif");

        if (notif == "true") {
            localStorage.setItem("notif", false);
            setShow(true);
        } else setShow(false);
    }, [])

    return (
        <section className="pt-page" data-id="contact">
            <div className="section-inner custom-page-content">

                <div className="section-title-block second-style">
                    <h2 className="section-title">{t('contact.title')}</h2>
                    <h5 className="section-description">{t('contact.sub')}</h5>
                </div>
                <div className="section-content">
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <div className="col-inner bs-30">
                                <div className="lm-info-block gray-default"> <i className="lnr lnr-map-marker"></i>
                                    <h4>{t('contact.location')}</h4> <span className="lm-info-block-value"></span> <span className="lm-info-block-text"></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-4">
                            <div className="col-inner bs-30">
                                <div className="lm-info-block gray-default"> <i className="lnr lnr-envelope"></i>
                                    <h4>contact@naceur-abdeljalil.com
                                    </h4> <span className="lm-info-block-value"></span> <span className="lm-info-block-text"></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-4">
                            <div className="col-inner bs-30">
                                <div className="lm-info-block gray-default"> <i className="lnr lnr-checkmark-circle"></i>
                                    <h4>{t('contact.msg1')}</h4> <span className="lm-info-block-value"></span>
                                    <span className="lm-info-block-text"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className=" col-xs-12 col-sm-8 offset-sm-1 offset-sm-2">
                        <div className="col-inner ts-20">
                            <div className="block-title">
                                <h3>{t('contact.msg2')}</h3>
                            </div>

                            {show ? <div className="block-notif">
                                <h5>{t('contact.success')}</h5>
                            </div> : null}


                            <form id="contact_form" className="contact-form" action="send_mail.php" method="post">
                                <div className="messages"></div>
                                <div className="controls two-columns">
                                    <div className="fields clearfix">
                                        <div className="left-column">
                                            <div className="form-group form-group-with-icon">
                                                <input id="form_name" type="text" name="name" className="form-control"
                                                    placeholder={t('contact.form.fullname')} required="required" data-error="Name is required." />
                                                <div className="form-control-border"></div>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                            <div className="form-group form-group-with-icon">
                                                <input id="form_email" type="email" name="email" className="form-control"
                                                    placeholder={t('contact.form.email')} required="required" data-error="Valid email is required." />
                                                <div className="form-control-border"></div>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                            <div className="form-group form-group-with-icon">
                                                <input id="form_subject" type="text" name="subject"
                                                    className="form-control" placeholder={t('contact.form.subject')} required="required" data-error="Subject is required." />
                                                <div className="form-control-border"></div>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="right-column">
                                            <div className="form-group form-group-with-icon">
                                                <textarea id="form_message" name="message" className="form-control"
                                                    placeholder={t('contact.form.message')} rows="7" required="required" data-error="Please, leave me a message."></textarea>
                                                <div className="form-control-border"></div>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="submit" className="button btn-send" name="submit-contact"
                                        value={t('contact.form.submit')} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;