import './helpers/i18n';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import promiseMiddleware from 'redux-promise';
import reducers from './store/configureStore';
import { HashRouter } from "react-router-dom";

import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from "react-router-dom";

// Import compoenents
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from "./components/Portfolio";
import Blog from './components/Blog';
import Contact from './components/Contact';
import Loader from './components/Loader';

const createHistory = require("history").createBrowserHistory;
const history = createHistory();
const middleware = routerMiddleware(history);

const store = createStore(reducers, applyMiddleware(middleware, promiseMiddleware, thunk));

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback="Loading ..." >
            <Loader />
            <div id="page" class="page">
                <div className="mobile-header mobile-visible">
                    <div className="mobile-logo-container">
                        <div className="mobile-header-image">
                            <a href="#"> <img src="./assets/images/photo.jpg" alt="image" /> </a>
                        </div>
                        <div className="mobile-site-title"><a href="#">Abdeljalil NACEUR</a></div>
                    </div>
                    <a className="menu-toggle mobile-visible"
                        onClick={(e) => {
                            document.getElementById("site_header").classList.toggle("mobile-menu-hide")
                        }}
                    > <i className="fa fa-bars"></i> </a>
                </div>
                <Header />

                <div className="lmpixels-arrows-nav">
                    <div className="lmpixels-arrow-left"><i className="lnr lnr-chevron-left"></i></div>
                    <div className="lmpixels-arrow-right"><i className="lnr lnr-chevron-right"></i></div>
                </div>

                <div id="main" className="site-main">
                    <div className="pt-wrapper">
                        <div className="subpages">
                            <Router history={history}>
                                <Switch>
                                    <HashRouter>
                                        <Route exact path="/" component={Home} />
                                        <Route exact path="/about" component={About} />
                                        <Route exact path="/resume" component={Resume} />
                                        <Route exact path="/portfolio" component={Portfolio} />
                                        <Route exact path="/blog" component={Blog} />
                                        <Route exact path="/contact" component={Contact} />
                                        {/* <Redirect to="/" /> */}
                                    </HashRouter>
                                </Switch>
                            </Router>
                        </div>
                    </div>
                </div>
            </div>
        </Suspense>
    </Provider >, document.getElementById('root'));
serviceWorker.unregister();

