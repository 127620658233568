/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <section className="pt-page" data-id="about-me">
      <div className="section-inner custom-page-content">
        <div className="section-title-block second-style">
          <h2 className="section-title">{t('about.header')}</h2>
        </div>
        <div className="section-content">
          <div className="row bs-30">
            <div className="col-xs-6 col-sm-6">
              <h3>{t('about.title')}</h3>
              <p>{t('about.desc1')}</p>
              <p>{t('about.desc2')} </p>
              <p>{t('about.desc3')}</p>
            </div>

            <div className="col-xs-6 col-sm-6">
              {/* <img src='./assets/images/page-2Style .png' /> */}
              <img src="./assets/images/developer.png" />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="col-inner">
                <div className="block-title">
                  <h3>{t('about.title2')}</h3>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner">
                <div className="info-list-w-icon">

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-laptop-phone"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service1')}</h4>
                    </div>
                  </div>

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-drop"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service2')}</h4>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner">
                <div className="info-list-w-icon">

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-cloud"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service3')}</h4>
                    </div>
                  </div>

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-store"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service4')}</h4>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner">
                <div className="info-list-w-icon">

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-pencil"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service5')}</h4>
                    </div>
                  </div>

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-flag"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service6')}</h4>
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner">
                <div className="info-list-w-icon">

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-database"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service7')} </h4>
                    </div>
                  </div>

                  <div className="info-block-w-icon">
                    <div className="ci-icon"> <i className="lnr lnr-rocket"></i> </div>
                    <div className="ci-text">
                      <h4>{t('about.service8')}</h4>
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
          {/* <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="col-inner ts-25 bs-50">
                <div className="block-title">
                  <h3>Testimonials<span></span></h3>
                </div>
                <div className="testimonials owl-carousel">
                  <div className="testimonial-item">
                    <div className="testimonial-content">
                      <div className="testimonial-text">
                        <p>Nam tempor commodo mi id sodales. Aenean sit amet nibh nec sapien consequat porta a sit amet diam.</p>
                      </div>
                    </div>
                    <div className="testimonial-credits">
                      <div className="testimonial-picture"> <img src="./assets/images/testimonials/testimonial-2.jpg" alt="Gary Johnson" /> </div>
                      <div className="testimonial-author-info">
                        <p className="testimonial-author">Gary Johnson</p>
                        <p className="testimonial-firm">Locost Accessories</p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-content">
                      <div className="testimonial-text">
                        <p>Donec eu est vel metus consequat volutpat. Nunc aliquet euismod mauris, a feugiat urna ullamcorper non.</p>
                      </div>
                    </div>
                    <div className="testimonial-credits">
                      <div className="testimonial-picture"> <img src="./assets/images/testimonials/testimonial-1.jpg" alt="Daniel Pringle" /> </div>
                      <div className="testimonial-author-info">
                        <p className="testimonial-author">Daniel Pringle</p>
                        <p className="testimonial-firm">Rolling Thunder</p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-content">
                      <div className="testimonial-text">
                        <p>Etiam pretium ipsum quis justo dictum accumsan. Phasellus egestas odio a velit scelerisque, imperdiet elementum lorem aliquet.</p>
                      </div>
                    </div>
                    <div className="testimonial-credits">
                      <div className="testimonial-picture"> <img src="./assets/images/testimonials/testimonial-3.jpg" alt="Billy Adams" /> </div>
                      <div className="testimonial-author-info">
                        <p className="testimonial-author">Billy Adams</p>
                        <p className="testimonial-firm">Sagebrush</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           */}

          {/* <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="col-inner">
                <div className="block-title">
                  <h3>Clients<span></span></h3>
                </div>
                <div className="clients owl-carousel">
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-7.png" alt="Logo" /> </a>
                  </div>
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-6.png" alt="Logo" /> </a>
                  </div>
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-5.png" alt="Logo" /> </a>
                  </div>
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-4.png" alt="Logo" /> </a>
                  </div>
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-3.png" alt="Logo" /> </a>
                  </div>
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-2.png" alt="Logo" /> </a>
                  </div>
                  <div className="client-block">
                    <a href="#" target="_blank" title="Logo">
                      <img src="./assets/images/clients/client-1.png" alt="Logo" /> </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <br></br>
          <br></br>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="block-title">
                <h3>{t('about.funTitle')}</h3>
              </div>
            </div>


            <div className="col-xs-12 col-sm-3">
              <div className="col-inner bs-30">
                <div className="lm-info-block gray-bg"> <i className="lnr lnr-clock"></i>
                  <h4>{t('about.fun1')} </h4> <span className="lm-info-block-value">8,640</span>
                  <span className="lm-info-block-text"></span>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner bs-30">
                <div className="lm-info-block"> <i className="lnr lnr-checkmark-circle"></i>
                  <h4>{t('about.fun2')}</h4> <span className="lm-info-block-value">
                    43
                  </span> <span className="lm-info-block-text"></span>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner bs-30">
                <div className="lm-info-block gray-bg"> <i className="lnr lnr-coffee-cup"></i>
                  <h4>{t('about.fun3')}</h4> <span className="lm-info-block-value">12,456</span>
                  <span className="lm-info-block-text"></span>
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-3">
              <div className="col-inner bs-30">
                <div className="lm-info-block"> <i className="lnr lnr-smile"></i>
                  <h4>{t('about.fun4')}</h4> <span className="lm-info-block-value">12</span>
                  <span className="lm-info-block-text"></span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  )
}

export default About;