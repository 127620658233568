import React from 'react';
import { useTranslation } from 'react-i18next';
import Particles from 'react-particles-js';
import { Typing, TypingStep } from "typing-effect-reactjs";

const Home = () => {
  const { t } = useTranslation();


  return (
    <section data-id="home" data-title="Home" className="pt-page pt-page-home start-page">
      {/* <div className="main-particles">
        <Particles
          params={{
            "particles": {
              "number": {
                "value": 100,
                "density": {
                  "enable": true,
                  "value_area": 500
                }
              },
              "color": {
                "value": "#fff"
              },
              "shape": {
                "type": "edge",
                "stroke": {
                  "width": 0,
                  "color": "#fff"
                },
                "polygon": {
                  "nb_sides": 5
                },
                "image": {
                  "src": "img/github.svg",
                  "width": 100,
                  "height": 100
                }
              },
              "opacity": {
                "value": 0.5,
                "random": false,
                "anim": {
                  "enable": false,
                  "speed": 1,
                  "opacity_min": 0.1,
                  "sync": false
                }
              },
              "size": {
                "value": 5,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 40,
                  "size_min": 0.1,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": true,
                "distance": 150,
                "color": "#fff",
                "opacity": 0.4,
                "width": 1
              },
              "move": {
                "enable": true,
                "speed": 6,
                "direction": "top",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 600
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "repulse"
                },
                "onclick": {
                  "enable": true,
                  "mode": "push"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 250,
                  "line_linked": {
                    "opacity": 1
                  }
                },
                "bubble": {
                  "distance": 600,
                  "size": 80,
                  "duration": 2,
                  "opacity": 8,
                  "speed": 3
                },
                "repulse": {
                  "distance": 200
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true,
            "config_demo": {
              "hide_card": false,
              "background_color": "#b61924",
              "background_image": "",
              "background_position": "50% 50%",
              "background_repeat": "no-repeat",
              "background_size": "cover"
            }
          }}
        />
      </div> */}

      <div style={{ backgroundImage: "url(./assets/images/img_bg_1.png)" }} className="section-inner vcentered">
        {/* <!-- <div className="mask"></div> --> */}
        {/* <div class="overlay"></div> */}

        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="owl-carousel text-rotation">
              {/* <div className="item">
                <div className="sp-subtitle1">{t('home.name')}</div>
              </div> */}
              {/* <div className="item">
                  <div className="sp-subtitle">Frontend-developer</div>
                </div> */}
            </div>
            <div className="title-block">
              {/* <h2>
                <Typing
                  element="h2"
                  typeSpeed="70"
                  deleteSpeed="70"
                  cursorColor="white"
                  text={[
                    "CTO consultant",
                    "IT project manager",
                    "IT project director",
                    "Tech lead",
                  ]}
                  smartBackspace
                />

              </h2> */}
            </div>
          </div>
        </div>
      </div>


    </section>

  )
}

export default Home;