import React, { useState, useEffect } from 'react';

const Loader = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1500)
    }, [])

    return (
        <React.Fragment>
            {isLoading ?
                    <div class="preloader" >
                        <div class="preloader-animation">
                            <div class="preloader-spinner"> </div>
                        </div>
                    </div >
                    : null}
        </React.Fragment>
    )
}

export default Loader;