import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const Blog = () => {
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();

  // TODO MAKE IT YNAMIC
  // useEffect(() => {
  //   // axios.get("https://medium.com/feed/@dev4life.tech", {
  //     axios.get("https://medium.com/feed/@dev4life.tech?forma=json"
  //     // axios.get("https://api.medium.com/v1/users/@dev4life.tech/publications"
  //     // axios.get("https://api.medium.com/v1/users/users/dev4life.tech/posts"
  //     // axios.get("https://medium.com/@dev4life.tech",
  //     ,{
  //       headers: {
  //         'Authorization': `Bearer 27b2b540a552f5795a4a59ff071fdb303e2c206ada2acd2488ad32f4b017b29f0`,
  //         'Access-Control-Allow-Origin': `*`,
  //         "Content-Type": "application/json"
  //       }
  //     }
  //     )
  //     .then(data => {
  //     console.log("===>", data);
  //   }).catch(err => console.log("err ->", err))
  // }, [articles])

  return (
    <section className="pt-page" data-id="blog">
      <div className="section-inner custom-page-content">
        <div className="section-title-block second-style">
          <h2 className="section-title">{t('blog.title')}</h2>
          <h5 className="section-description">{t('blog.sub')}</h5>
        </div>

        <div className="section-content">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="blog-masonry two-columns clearfix">

                <div className="item post-1">
                  <div className="blog-card">
                    <div className="media-block">
                      <div className="category">
                        <a href="https://medium.com/@dev4life.tech/react-native-authentication-with-google-oauth-2-5f17fe3a8b53" >Medium</a> </div>
                      <a href="https://medium.com/@dev4life.tech/react-native-authentication-with-google-oauth-2-5f17fe3a8b53" >
                        <img src="https://miro.medium.com/max/1050/1*RjAN4m9lnNpt6VXecZwvsg.png"
                          className="size-blog-masonry-image-two-c"
                           title="Medium" />
                        <div className="mask"></div>
                      </a>
                    </div>
                    <div className="post-info">
                      <div className="post-date">{t('blog.posts.date1')}</div>
                      <a href="https://medium.com/@dev4life.tech/react-native-authentication-with-google-oauth-2-5f17fe3a8b53">
                        <h4 className="blog-item-title">
                          {t('blog.posts.desc1')}
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="item post-2">
                  <div className="blog-card">
                    <div className="media-block">
                      <div className="category"> <a href="https://medium.com/voice-tech-podcast/build-a-chatbot-powered-ai-with-node-react-and-socketio-eaf6bfee43d2" >Medium</a> </div>
                      <a href="https://medium.com/voice-tech-podcast/build-a-chatbot-powered-ai-with-node-react-and-socketio-eaf6bfee43d2">
                        <img src="https://miro.medium.com/max/1050/1*7nZ2Snr8slu8F8WyesJNiA.png"
                          className="size-blog-masonry-image-two-c"
                           title="Medium" />
                        <div className="mask"></div>
                      </a>
                    </div>
                    <div className="post-info">
                      <div className="post-date">{t('blog.posts.date2')}</div>
                      <a href="https://medium.com/voice-tech-podcast/build-a-chatbot-powered-ai-with-node-react-and-socketio-eaf6bfee43d2">
                        <h4 className="blog-item-title">
                          {t('blog.posts.desc2')}
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="item post-3">
                  <div className="blog-card">
                    <div className="media-block">
                      <div className="category">
                        <a href="https://medium.com/@dev4life.tech/how-to-set-up-react-with-redux-and-i18n-fc89043686df" >Medium</a> </div>
                      <a href="https://medium.com/@dev4life.tech/how-to-set-up-react-with-redux-and-i18n-fc89043686df" >
                        <img src="https://miro.medium.com/max/1050/1*lBkh23NUoPly19mlofisLA.png"
                          className="size-blog-masonry-image-two-c"
                           title="Medium" />
                        <div className="mask"></div>
                      </a>
                    </div>
                    <div className="post-info">
                      <div className="post-date">{t('blog.posts.date3')}</div>
                      <a href="https://medium.com/@dev4life.tech/how-to-set-up-react-with-redux-and-i18n-fc89043686df">
                        <h4 className="blog-item-title">
                          {t('blog.posts.desc3')}
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="item post-4">
                  <div className="blog-card">
                    <div className="media-block">
                      <div className="category"> <a href="https://medium.com/@dev4life.tech/how-to-dockerize-a-prestashop-application-for-your-e-commerce-website-development-deployment-ba568eddbe91" >Medium</a> </div>
                      <a href="https://medium.com/@dev4life.tech/how-to-dockerize-a-prestashop-application-for-your-e-commerce-website-development-deployment-ba568eddbe91">
                        <img src="https://miro.medium.com/max/1050/1*QmfYxQ_gAaEQ7RdnHTN4_g.png"
                          className="size-blog-masonry-image-two-c"
                           title="Medium" />
                        <div className="mask"></div>
                      </a>
                    </div>
                    <div className="post-info">
                      <div className="post-date">{t('blog.posts.date4')}</div>
                      <a href="https://medium.com/@dev4life.tech/how-to-dockerize-a-prestashop-application-for-your-e-commerce-website-development-deployment-ba568eddbe91">
                        <h4 className="blog-item-title">
                          {t('blog.posts.desc4')}
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="item post-5">
                  <div className="blog-card">
                    <div className="media-block">
                      <div className="category"> <a href="https://medium.com/@dev4life.tech/setup-reactjs-with-i18n-854c4effbd2" >Medium</a> </div>
                      <a href="https://medium.com/@dev4life.tech/setup-reactjs-with-i18n-854c4effbd2">
                        <img src="https://miro.medium.com/max/1050/1*OaHqEogkeTulrIlSaTWKaw.png"
                          className="size-blog-masonry-image-two-c"
                           title="Medium" />
                        <div className="mask"></div>
                      </a>
                    </div>
                    <div className="post-info">
                      <div className="post-date">{t('blog.posts.date5')}</div>
                      <a href="https://medium.com/@dev4life.tech/setup-reactjs-with-i18n-854c4effbd2">
                        <h4 className="blog-item-title">
                          {t('blog.posts.desc5')}
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Blog;