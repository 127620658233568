import React from 'react';
import { useTranslation } from 'react-i18next';

const Portfolio = () => {
  const { t } = useTranslation();

  return (
    <section className="pt-page" data-id="portfolio">
      <div className="section-inner custom-page-content">
        <div className="section-title-block second-style">
          <h2 className="section-title">{t('protofolio.title')}</h2>
          <h5 className="section-description">{t('protofolio.sub')}</h5>
        </div>
        <div className="section-content">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="portfolio-content">
                {/* <ul className="portfolio-filters">
                    <li className="active"> <a className="filter btn btn-sm btn-link" data-group="category_all">All</a> </li>
                    <li> <a className="filter btn btn-sm btn-link" data-group="category_media">Media</a> </li>
                    <li> <a className="filter btn btn-sm btn-link" data-group="category_mockups">Mockups</a> </li>
                    <li> <a className="filter btn btn-sm btn-link" data-group="category_soundcloud">SoundCloud</a> </li>
                    <li> <a className="filter btn btn-sm btn-link" data-group="category_vimeo-videos">Vimeo Videos</a> </li>
                    <li> <a className="filter btn btn-sm btn-link" data-group="category_youtube-videos">YouTube Videos</a> </li>
                  </ul> */}
                <div className="portfolio-grid four-columns">

                <figure className="item standard" data-groups='["category_all", "category_media"]'>
                    <div className="portfolio-item-img"> 
                    <img src="./assets/images/cover.jpg" alt={t('protofolio.card.alt1')} title="" />
                      <a href="https://github.com/abnaceur/habee"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title1')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name1')}</h4> 
                    <span className="category">{t('protofolio.card.tech1')}</span>
                  </figure>

                  <figure className="item lbvideo" data-groups='["category_all", "category_vimeo-videos"]'>
                      <div className="portfolio-item-img"> 
                    <img src="./assets/images/devtube.jpeg" alt={t('protofolio.card.alt2')} title="" />
                      <a href="https://github.com/abnaceur/devtube"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title2')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name2')}</h4> 
                    <span className="category">{t('protofolio.card.tech2')}</span>
                  </figure>

                  <figure className="item standard" data-groups='["category_all", "category_media"]'>
                     <div className="portfolio-item-img"> 
                    <img src="./assets/images/clacile.png" alt={t('protofolio.card.alt3')} title="" />
                      <a href="https://github.com/abnaceur/clacile"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title3')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name3')}</h4> 
                    <span className="category">{t('protofolio.card.tech3')}</span>
                  </figure>

                  <figure className="item lbimage" data-groups='["category_all", "category_mockups"]'>
                     <div className="portfolio-item-img"> 
                    <img src="./assets/images/img-4.jpg" alt={t('protofolio.card.alt4')} title="" />
                      <a href="https://github.com/abnaceur/MoviesHub"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title4')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name4')}</h4> 
                    <span className="category">{t('protofolio.card.tech4')}</span>
                  </figure>

                  <figure className="item lbvideo" data-groups='["category_all", "category_youtube-videos"]'>
                    <div className="portfolio-item-img"> 
                    <img src="./assets/images/img-5.jpg" alt={t('protofolio.card.alt5')} title="" />
                      <a href="https://github.com/abnaceur/datingapp"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title5')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name5')}</h4> 
                    <span className="category">{t('protofolio.card.tech5')}</span>
                  </figure>

                  <figure className="item standard" data-groups='["category_all", "category_soundcloud"]'>
                    <div className="portfolio-item-img"> 
                    <img src="./assets/images/img-6.jpg" alt={t('protofolio.card.alt6')} title="" />
                      <a href="https://github.com/abnaceur/ai_projects"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title6')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name6')}</h4> 
                    <span className="category">{t('protofolio.card.tech6')}</span>
                  </figure>

                  <figure className="item standard" data-groups='["category_all", "category_soundcloud"]'>
                    <div className="portfolio-item-img"> 
                    <img src="./assets/images/img-7.jpg" alt={t('protofolio.card.alt7')} title="" />
                      <a href="https://github.com/abnaceur/angelhack2018x42"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title7')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name7')}</h4> 
                    <span className="category">{t('protofolio.card.tech7')}</span>
                  </figure>

                  <figure className="item standard" data-groups='["category_all", "category_soundcloud"]'>
                    <div className="portfolio-item-img"> 
                    <img src="./assets/images/img-8.jpg" alt={t('protofolio.card.alt8')} title="" />
                      <a href="https://github.com/abnaceur/hackathon-uae"
                        className="lightbox mfp-iframe" title={t('protofolio.card.title8')} target="_blank"></a>
                    </div>
                    <h4 className="name">{t('protofolio.card.name8')}</h4> 
                    <span className="category">{t('protofolio.card.tech8')}</span>
                  </figure>





{/*                                       
                    <figure className="item standard" data-groups='["category_all", "category_media"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/2.jpg" alt="Media Project 2" title="" />
                        <a href="portfolio-1.html" className="ajax-page-load"></a>
                      </div><i className="far fa-file-alt"></i>
                      <h4 className="name">Media Project 2</h4> <span className="category">Media</span>
                    </figure>
                    
                    <figure className="item lbvideo" data-groups='["category_all", "category_vimeo-videos"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/3.jpg" alt="Vimeo Video 1" title="" />
                        <a href="https://player.vimeo.com/video/158284739" className="lightbox mfp-iframe" title="Vimeo Video 1"></a>
                      </div><i className="fas fa-video"></i>
                      <h4 className="name">Vimeo Video 1</h4> <span className="category">Vimeo
                                                Videos</span>
                    </figure>
                    
                    <figure className="item standard" data-groups='["category_all", "category_media"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/4.jpg" alt="Media Project 1" title="" />
                        <a href="portfolio-1.html" className="ajax-page-load"></a>
                      </div><i className="far fa-file-alt"></i>
                      <h4 className="name">Media Project 1</h4> <span className="category">Media</span>
                    </figure>
                    
                    <figure className="item lbimage" data-groups='["category_all", "category_mockups"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/5.jpg" alt="Mockup Design 1" title="" />
                        <a className="lightbox" title="Mockup Design 1" href="./assets/images/portfolio/5.jpg"></a>
                      </div><i className="far fa-image"></i>
                      <h4 className="name">Mockup Design 1</h4> <span className="category">Mockups</span>
                    </figure>
                    
                    <figure className="item lbvideo" data-groups='["category_all", "category_youtube-videos"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/6.jpg" alt="YouTube Video 1" title="" />
                        <a href="https://www.youtube.com/embed/bg0gv2YpIok" className="lightbox mfp-iframe" title="YouTube Video 1"></a>
                      </div><i className="fas fa-video"></i>
                      <h4 className="name">YouTube Video 1</h4> <span className="category">YouTube
                                                Videos</span>
                    </figure>
                    
                    <figure className="item standard" data-groups='["category_all", "category_media"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/5.jpg" alt="Media Project 1" title="" />
                        <a href="portfolio-1.html" className="ajax-page-load"></a>
                      </div><i className="far fa-file-alt"></i>
                      <h4 className="name">Media Project 3</h4> <span className="category">Media</span>
                    </figure>
                    
                    <figure className="item lbimage" data-groups='["category_all", "category_mockups"]'>
                      <div className="portfolio-item-img"> <img src="./assets/images/portfolio/6.jpg" alt="Mockup Design 1" title="" />
                        <a className="lightbox" title="Mockup Design 1" href="./assets/images/portfolio/6.jpg"></a>
                      </div><i className="far fa-image"></i>
                      <h4 className="name">Mockup Design 2</h4> <span className="category">Mockups</span>
                    </figure> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Portfolio;